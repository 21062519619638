import React from "react";
import { Helmet } from "react-helmet";

export default function News() {
  return (
    <main>
      <Helmet>
        <title>Francisco Martinez - Personal Website</title>
        <meta
          name="description"
          content="News. Francisco Martinez. Redesigning the Social through Ethnographic Experimentation."
        />
        <meta
          name="keywords"
          content="anthropology, ethnography, material culture, political discourses, repair practices, francisco martinez"
        />
      </Helmet>

      <div className="news">
        <div className="container">
          <div className="news-item">
            <h1 className="news-header">
              Unsettling Experimentation in Anthropology: Introducing a Network
              of Problems
            </h1>
            <p className="news-date">11 November 2024</p>
            <div className="news-img-container d-flex align-items-center">
              <img
                src="media/news-imgs/News-2.jpeg"
                alt="News"
                className="news-img"
              />
            </div>
            <h3 className="news-section-header">Overview</h3>
            <p className="news-text ms-4">
              Experimentation is becoming increasingly common in anthropology.
              Sometimes it unsettles traditional observational practices, opens
              the way for radical novel forms of representation or challenges
              the fixation of anthropology with a restricted notion of
              anthropos.
            </p>
            <p className="news-text ms-4">
              In this encounter, we want to think, reflect and speculate
              together how experimentation can challenge common instantiations
              and concerns within anthropology. Although experimentation
              unsettles some of our disciplinary foundations, we think this
              reorientation is worth pursuing for it opens a relevant network of
              problems for our discipline.
            </p>
            <p className="news-text ms-4">
              The workshop is organised in in two parts: First, the new
              convening team and two invited speakers reflect upon possibilities
              and challenges of mobilising ‘the experimental’ in different
              research context. Then we shall discuss pathways for the network’s
              future trajectories with the general public and the host
              laboratories at the Institute for European Ethnology.
            </p>
            <p className="news-text ms-4">
              <span className="news-underline">Convenors</span>: Adolfo
              Estalella, Elisabeth Luggauer, Maka Suarez, and Jonas Tinius
            </p>
            <p className="news-text ms-4 news-underline">Talks</p>
            <p className="news-text ms-4">
              Francisco Martínez,{" "}
              <span className="fw-bold">
                Mutual Redefinitions: Anthropology as a Practice and as a
                Discipline
              </span>
            </p>
            <p className="news-text ms-4">
              This paper provides a new take on the possibilities – and limits –
              of experimental collaborations for ethnographic research and how
              they might lead to shifts back in the disciplinary core. By
              showing how exhibitions can be used for knowledge-making, it
              expands the notion of field and fieldwork. The series of
              ethnographic experiments outlined here help us rethink and,
              eventually, unlearn our own tools and protocols of relevance in
              anthropology. Unlearning, however, is not what researchers are
              supposed to do in the field. Too-often boundary-work removes what
              gives rise to wonder in order to secure what counts as valid
              knowledge and by whom. A more inclusive take on fieldwork could
              benefit practitioners and the discipline itself, as
              anthropologists are seeking to reach wider publics and navigate
              the contemporary complexities of authority and representation.
            </p>
            <p className="news-text ms-4">
              Kiven Strohm,{" "}
              <span className="fw-bold">
                (I want to live on an) Experimental Plane: Diffracting art and
                anthropology collaborations
              </span>
            </p>
            <p className="news-text ms-4">
              When two practices or disciplines come together, whether formally
              or informally, too often one is subordinated to the other, or
              worse, they are asked to produce a bastard transdisciplinary child
              that blurs the boundaries between their different skills and modes
              of thought. For some years, those of us working in
              art/anthropology collaborations have sought to counter this
              tendency, proposing different approaches, such as “of/with/and”
              (Clarke). Working through these efforts, what I wish to share with
              all of you is an experiment. I want to further explore
              art/anthropology collaborations through two angles. First, I wish
              to elaborate on these collaborations by way of a diffractive
              “method” (Haraway, Barad) to consider the interference patterns
              that emerge when reading these different practices through each
              other. My plan is to maintain and heighten the distinct skills and
              modes of thought in these practices, and, in so doing, consider
              how this might produce new forms of inquiry that engenders
              creative and unexpected outcomes. Second, I want to then consider
              how this might inform an art/anthropology research practice as a
              ‘plane of experimentation’ for what art and anthropology can do,
              together/apart. It will be a bit of adventure, and, I hope, the
              opening of a new terrain.
            </p>
            <h3 className="news-section-header">Schedule</h3>
            <div className="news-schedule ms-4">
              <p className="news-underline">
                <span>November 15, 2024</span>
              </p>
              <p className="news-underline">#Colleex Workshop</p>
              <p>
                Institute for European Ethnology, Humboldt University of Berlin
              </p>
              <p>14.30 - 17.30 pm</p>
            </div>
          </div>
          <hr></hr>
          <div className="news-item">
            <h1 className="news-header">
              Redesigning the Social through Ethnographic Experimentation
            </h1>
            <p className="news-date">5 October 2024</p>
            <div className="news-img-container d-flex align-items-center">
              <img
                src="media/news-imgs/Flamingot news.jpg"
                alt="News"
                className="news-img"
              />
            </div>
            <h3 className="news-section-header">Overview</h3>
            <p className="news-text ms-4">
              This seminar is an appeal to sketching out experimental
              ethnographic interventions, while aiming to work out what
              constitutes, both by extension and comprehension, the concept of
              the social. Through a series of open-format presentations, we’ll
              reflect on how field research can potentially enact, perform, or
              stage inventive ways of composing the world, expanding in turn the
              role of social research in the contemporary.
            </p>
            <h3 className="news-section-header">Schedule</h3>
            <div className="news-schedule ms-4">
              <p className="news-underline">
                <span>November 4, 2024</span>
              </p>
              <p className="news-underline">
                Metsätalo, C505 (sali 31, 5th floor), Unioninkatu 40, University
                of Helsinki
              </p>
              <p>Organiser: Francisco Martínez (Tampere University)</p>
              <p className="news-underline">Provisory Schedule:</p>
              <p>10.00</p>
              <p>Patrick Laviolette (Masaryk University)</p>
              <p>Adolfo Estalella (Spanish National Research Council)</p>
              <p>Eeva Berglund (Aalto University, Helsinki)</p>
              <p>Guy Julier (Aalto University, Helsinki)</p>
              <p>12.00</p>
              <p>Lunch</p>
              <p>13.00</p>
              <p>
                Tomás Errázuriz &amp; Ricardo Greene (Universidad Andrés Bello
                &amp; San Sebastián, Chile)
              </p>
              <p>Eerika Koskinen-Koivisto (University of Jyväskylä)</p>
              <p>
                Olli Pyyhtinen, Sonja Lampinen &amp; Niina Uusitalo (Tampere
                University)
              </p>
              <p>14.30</p>
              <p>Aurora del Rio and Katri Naukkarinen (Aalto University)</p>
              <p>Erich Berger (University of Oulu)</p>
              <p>Nastasia Fomina (Aalto University)</p>
              <p>Gregoire Rousseau (Station of commons, Lumbung Radio)</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
